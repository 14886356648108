<template>
  <div class="script-succeed">
    <div class="script-succeed-icon">
      <img
        src="../../../assets/image/success.png"
        alt=""
        style="margin-bottom: 30px"
      />
      <span>{{ msg ? "编辑成功!" : "创建成功!" }}</span>
    </div>
    <div class="getBack">
      <el-button class="getBackBtn" @click="backTo">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewAdvertisingPlanSuccess",
  data() {
    return {
      msg: "",
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.msg = this.$route.query.id;
    }
  },
  methods: {
    backTo() {
      this.$router.push({
        path: "/scriptManagement/ScriptListData",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.script-succeed {
  padding-bottom: 60px;
  .script-succeed-icon {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 52px;
      color: #20d28c;
    }
    span {
      color: #343434;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .getBack {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .getBackBtn {
      width: 100px;
      height: 40px;
      background: #ffffff;
      line-height: 0;
      border-radius: 2px;
    }
  }
}
</style>
